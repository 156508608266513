<template>
    <div id="app">
        <transition name="page">
        <router-view></router-view>
        </transition>
        <spinner :loading="LoadingStatus"></spinner>   
    </div>
</template>

<script>
import router from "@/script/router";
import bus from "@/script/bus.js";
import Spinner from './components/common/CommonSpinner.vue';
import axios from "axios";

export default {
    name: "App",
    components:{
        Spinner, 
    },
    created() {
        this.logger.debug(this, "created()");
        let scriptTag = document.createElement('link');
        scriptTag.setAttribute('rel', 'icon');
        scriptTag.setAttribute('href', '/img/favicon.png')
        scriptTag.setAttribute('type', 'image/x-icon');
        document.head.appendChild(scriptTag);

        this.intervalErrCount = 0;

        bus.$on('start:spinner', this.startSpinner);
        bus.$on('end:spinner', this.endSpinner);


        console.log(localStorage.getItem("lang"))
        if(localStorage.getItem("lang") != '')
        {
          this.$i18n.locale = this.locale;
          this.$validator.locale = this.locale;
        }
          this.initInterceptor();

    },
    beforeDestroy(){
        bus.$off('start:spinner');
        bus.$off('end:spinner');
    },    
    data() {
        return {
            LoadingStatus : false,
            locale : localStorage.getItem("lang"),
        };
    },
    methods: {
        login() {
            router.push("/user/login");
        },
        info() {
            router.push("/user/info");
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                clearInterval(this.interval1);
                this.auth.logout();
            }
        },
        extend(){
            this.auth.info();
            this.extendDecision = false;
        },
            startSpinner(){
        this.LoadingStatus = true;
        },
        endSpinner(){
            this.LoadingStatus = false;
        },
        initInterceptor(){
            let thiss = this;
            // 스피너 인터셉터 (request)
            axios.interceptors.request.use(
                function (config) {
                    thiss.LoadingStatus = true;
                    // console.log("spinner 인터셉터");
                    return config;
                },
                function (error) {
                    return Promise.reject(error);
                }
            );
            // 스피너 인터셉터 (response)
            axios.interceptors.response.use(
                function (response) {
                    thiss.LoadingStatus = false;
                    // console.log("spinner 인터셉터");
                    return response;
                },
                function (error) {
                    thiss.LoadingStatus = false;
                    return Promise.reject(error);
                }
            );
        }
    },
};
</script>

<style>
#app {
    height: 100%;
}
</style>
