import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

Vue.prototype.authRoutes = [
    {
        path: "/system",
        roleCd: "ROLE_ADMIN"
    },
    {
        path: "/manage",
        roleCd: "ROLE_ADMIN"
    },
    {
        path: "/applicant",
        roleCd: "ROLE_ADMIN, ROLE_APPLICANT"
    }
];

// NavigationDuplicated 대응
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
            throw err;
        }
    });
};
// 메인홈페이지
const routes = [
    {
        path: "/home",
        component: () =>
            import ("@/components/applicant/applicant/ApplicantSelect.vue"),
    },
    {
        path: "/user",
        component: () =>
            import ("@/components/user/UserLayout.vue"),
        children: [
            {
            path: "/",
            redirect: "/user/info",
            },
            {
                path: "info",
                component: () =>
                    import ("@/components/user/UserInfo.vue")
            },
            {
                name: "login", //사용자로그인
                path: "login",
                component: () =>
                    import ("@/components/user/UserLogin.vue"),
            },
            {
                name: "managelogin", //관리자로그인
                path: "managelogin",
                component: () =>
                    import ("@/components/user/ManageLogin.vue"),
            },
            {
                name: "manageloginsuperuser", //관리자로그인 - Super User
                path: "manageloginsuperuser",
                component: () =>
                    import ("@/components/user/ManageLoginSuperUser.vue"),
            },
            {
                name: "membershipagree", //회원가입동의서
                path: "membershipagree",
                component: () =>
                    import ("@/components/user/MebershipAgree.vue"),
            },
            {
                name: "joinmembership", //회원가입
                path: "joinmembership",
                component: () =>
                    import ("@/components/user/JoinMembership.vue"),
            },
            {
                name: "findid", //아이디찾기
                path: "findid",
                component: () =>
                    import ("@/components/user/FindId.vue"),
            },
            {
                name: "findpassword", //비밀번호찾기
                path: "findpassword",
                component: () =>
                    import ("@/components/user/FindPassword.vue"),
            },
            {
                name: "emailvalid", // 이메일 인증
                path: "emailvalid",
                component: () =>
                    import ("@/components/user/EmailValid.vue"),
            }
        ],
    },
    {
        path: "/applicant",
        component: () =>
            import ("@/components/applicant/ApplicantLayout.vue"),
            props:true,
        children: [
            {
            path: "/",
            redirect: "/applicant/form",
            },
            {
                path: "form", //지원서
                name: "form",
                component: () =>
                    import ("@/components/applicant/applicant/ApplicantForm.vue"),
            },
            {
                path: "applicant/pay", //특별전형 - 전형료결제
                component: () => import ("@/components/applicant/applicant/PayScreening.vue"),
            },
            {
                path: "resultdocument", //서류심사결과확인
                component: () =>
                    import ("@/components/applicant/resultdocument/ResultDocumentVeiw.vue"),
            },
            {
                path: "lastresult", //최종합격자발표
                component: () =>
                    import ("@/components/applicant/lastresult/LastResultView.vue"),
            },
            {
                path: "tutionregister", //입학등록
                component: () =>
                    import ("@/components/applicant/tutionregister/TutionRegisterEdit.vue"),
            },
            {
                path: "tutionregister/view", //입학등록-조회
                component: () =>
                    import ("@/components/applicant/tutionregister/TutionRegisterView.vue"),
            },
            {
                path: "student/view", // 학번 조회 화면
                component: () =>
                    import ("@/components/applicant/student/StudentNoView.vue"),
            },
            {
                path: "userinfo", //비밀번호 수정
                component: () =>
                    import ("@/components/applicant/applicant/UserInfoEdit.vue"),
            },
            {
                path: "professor",
                component: () =>
                    import("@/components/applicant/professor/ProfessorView.vue"),
            }
        ],
    },
    {
        name: "PaymentsSuccess",
        path: "/payments/success",
        component: () =>
            import("@/components/applicant/payments/paymentsSuccess.vue")
    },
    {
        name: "PaymentsFail",
        path: "/payments/fail",
        component: () =>
            import("@/components/applicant/payments/paymentsFail.vue")
    },
    {
        path: "/link/reference/",
        redirect: "/common/notfound",
    },
    {
        path: "/link/reference/:discern",
        component: () =>
            import("@/components/applicant/link/ReferenceEdit.vue"),
    },
    {
        path: "/common/setlocale",
        component: () =>
            import ("@/components/common/SetLocale"),
    },
    {
        path: "/",
        redirect: "/common/setlocale",
    },
    {
        path: "/common/notfound",
        component: () =>
            import ("@/components/common/NotFound"),
    },
    {
        path: "*",
        component: () =>
            import ("@/components/common/NotFound"),
    },
    {
        path: "/common/maintenance",
        component: () =>
            import ("@/components/common/Maintenance"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) =>
{
    if(!to.path.includes("/link") && to.path != "/common/setlocale" && localStorage.getItem("lang") != "ko" && localStorage.getItem("lang") != "en")
    {
        router.push("/common/setlocale")
    }
    else{
        // 권한 체크
        let roleCd = sessionStorage.getItem("roleCd");

        // console.log("roleCd: " + roleCd)
        let routeNext = true;

        for(let path of Vue.prototype.authRoutes)
        {
            if(to.path.indexOf(path.path) >= 0)
            {
                routeNext = false;
                if((roleCd != null && roleCd.includes(path.roleCd)) || path.roleCd.includes('ROLE_ANONYMOUS'))
                {
                    routeNext = true;
                    break;
                }
            }
        }

        if(routeNext)
        {
            next();
        }
        else{
            if(roleCd != null)
            {
                alert("현재 로그인한 사용자는 `" + to.path + "`에 접근할 권한이 없습니다.");
                router.push("/");
            }
            else if(confirm("사용자의 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?")) {
                router.push({
                    path: to.fullPath.indexOf("/manage") >= 0 ? "/user/managelogin" : "/user/login",
                    query: {returnUrl: to.fullPath},
                    replace: true,
                });
            }
            else{
                router.push("/");
            }
        }
    }
});

export default router;