import Vue from "vue";
import VueAnalytics from "vue-analytics";
import App from "@/App.vue";
import logger from "@/script/logger";
import router from "@/script/router";
import utils from "@/script/utils";
import auth from "@/script/auth";
import i18n from '@/script/i18n';
import comApplicant from '@/script/comApplicant';
import axios from "axios";
import "@/assets/css/reset.css";
import "@/assets/css/common.css";
import "@/assets/css/font.css";
import "@/assets/css/icon.css";
import "@/assets/css/applicant.css";
import "@/assets/css/popup.css";

Vue.config.productionTip = false;

document.title = "UST 입학지원시스템";

//사용자 정보 확인
axios
.post("/common/auth/info.do")
.then((response) => {
  let result = response.data;
  if (result["userId"] !== "") {
    Object.keys(result).forEach((key) => {
      // console.log(key + ":" + result[key])
      if (key !== "" && key != "pathList") {
        sessionStorage.setItem(key, result[key]);
      }
    });
  }

  Vue.prototype.authRoutes = result.pathList;
  // console.log(Vue.prototype.authRoutes);

  i18n.setLocaleMessage("ko", result.locale_ko)
  i18n.setLocaleMessage("en", result.locale_en)

  // console.log(result.locale_ko)
  // console.log(result.locale_en)

  new Vue({
    logger, router, auth, utils, i18n, comApplicant, render: (h) => h(App),
  }).$mount("#app");

  Vue.prototype.auth.connect();
})
.catch((error) => {
  console.log(error + " and move to '/'");
  document.write('Can not open now.<br/>message : ' + JSON.stringify(error) + '<br/><input type="button" onclick="window.location.reload()" value="Retry">');
});

/*Validation*/
import VeeValidator from "vee-validate";
import ko from "vee-validate/dist/locale/ko.js";
import en from "vee-validate/dist/locale/en.js";

const localeInfo = {
  dictionary: {
    ko, en,
  },
}

i18n.locale = localStorage.getItem("lang");

Vue.use(VeeValidator, localeInfo);
Vue.use(VueAnalytics, {
  id: "G-EYKSPWYJT4"
})

// 점검 리다이렉트
// 1. axios가 없는 화면에서 redirect를 시키기 위한 셋팅
// axios.post("/system/maintenance/maintenance.do")
// .then((response) => {
//   if(response.data == []) console.log("ASD");
//   if (response.data.maintenace.maintenanceYn == "Y") {
//     router.replace("/common/maintenance");
//   }
// });

// // 2. axios를 사용 시 redirect를 시키기 위한 셋팅
// axios.interceptors.request.use(function (config) {
//   axios.post("/system/maintenance/maintenance.do")
//   .then((response) => {
//     if (response.data.maintenace.maintenanceYn == "Y") {
//       router.replace("/common/maintenance");
//     }
//   });
//   return config;
// }, function (err) {
//   // 오류를 리턴 해주기 전에 수행할 로직
//
//   return Promise.reject(err);
// });
